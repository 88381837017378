import { H } from "../../../Component";

interface ListIconProps { }

export default (props: ListIconProps, tsx: H) => (
    <svg
        height="16px"
        width="16px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
    >
        <path d="M6 14h9v1H6zm-5-1h3v3H1zm1 2h1v-1H2zM6 2v1h9V2zm0 7h9V8H6zM1 1h3v3H1zm1 2h1V2H2zM1 7h3v3H1zm1 2h1V8H2z"/>
    </svg>
);
